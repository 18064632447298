import { useEffect, useState } from "react";
import axios from "axios";

import "./App.css";

function App() {
  const [data, setData] = useState(null);

  useEffect(() => {
    axios
      .get("/data.json")
      .then((response) => setData(response.data))
      .catch((error) => console.log(error));
  }, []);

  return data ? (
    <div className="container">
      <div className="my-3">
        <nav className="nav justify-content-end">
          <a className="nav-link" href="https://bmshamsnahid.medium.com/">
            <i aria-hidden="true" className="fa fa-medium" /> blog
          </a>
        </nav>
      </div>
      <div className="row">
        <div className="col-md-4 col-lg-3 offset-lg-1 mb-5">
          <img src="./avatar.jpg" alt="Profile" className="avatar" />
          <h1 className="mt-4">{data.content.summary.name}</h1>
          <div className="my-2">
            {data.content.summary.designation.title} at <br />
            <a href={data.content.summary.designation.company.link}>
              {data.content.summary.designation.company.title}
            </a>
          </div>
          <div className="my-4">
            <div>
              <i aria-hidden="true" className="fa fa-map-marker" />
              <span>{data.content.summary.location.title}</span>
            </div>
            <div>
              <i aria-hidden="true" className="fa fa-phone fa-rotate-90" />
              <span>{data.content.summary.phoneNumber}</span>
            </div>
            <div>
              <i aria-hidden="true" className="fa fa-envelope" />
              <span>{data.content.summary.email}</span>
            </div>
          </div>
          <ul className="my-2 fa-2x social-info">
            {data.content.summary.profiles.map(({ link, title, className }) => (
              <li key={title}>
                <a href={link} title={title}>
                  <i aria-hidden="true" className={className} />
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="col-md-8 col-lg-7 mb-5">
          <div className="quote">
            <div className="quote-container">
              <blockquote>
                <p>
                  {
                    data.content.details.topBar.quotes.map(quote => (<span key={quote}>{quote} <br /></span>))
                  }
                </p>
              </blockquote>
            </div>
          </div>
          <div className="bio-section">
            <div>
              <div className="preference-label mb-1 mt-2">
                <span>{data.content.details.topBar.interests.title}</span>
              </div>
              <div>
                {data.content.details.topBar.interests.items.map(item => (<span key={item} className="tag interested-tag">{item}</span>))}
              </div>
            </div>
          </div>
          <div className="bio-section">
            <div>
              <div className="preference-label mb-1 mt-2">
                <span>{data.content.details.topBar.preferTo.title}</span>
              </div>
              <div>
                {data.content.details.topBar.preferTo.items.map(item => (<span key={item} className="tag">{item}</span>))}
              </div>
            </div>
          </div>
          <div className="bio-section">
            <div className="preference-label mb-1 mt-2">
              <span>{data.content.details.topBar.techStack.title}</span>
            </div>
            <div>
              {data.content.details.topBar.techStack.items.map(item => (<span key={item} className="tag">{item}</span>))}
            </div>
          </div>
          <hr />
          <section className="resume-section my-4">
            <h4>{data.content.details.experiences.title}</h4>
            <div className="row my-3" id="work">
              {data.content.details.experiences.items.map(({ designation, company, location, timeline, descriptions, domain }) => (<div className="col-12">
                <article>
                  <header className="my-2">
                    <h5>
                      {designation} at{" "}
                      <a
                        href={company.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {company.title}
                      </a>
                    </h5>
                    <div className="my-1">
                      <address>{location}</address>
                      <time>{timeline}</time>
                    </div>
                  </header>
                  <p className="my-2">
                    <ul>
                      {descriptions.map(description => <li key={description}>{description}</li>)}
                    </ul>
                  </p>
                  <footer className="my-3">
                    <div className="preference-label mb-1">
                      <span>{domain.title}</span>
                    </div>
                    {domain.items.map(item => <span key={item} className="tag">{item}</span>)}
                  </footer>
                </article>
              </div>))}
            </div>
          </section>
          <section className="resume-section my-4 mt-5">
            <h4>{data.content.details.projects.title}</h4>
            <div className="row my-3" id="projects">
              {data.content.details.projects.items.map(({ title, timeline, description, builtWith, link }) => (
                <div id="hash" className="col-lg-6 col-xs-12">
                  <article>
                    <header className="my-2">
                      <h5>
                        <a
                          href={link}
                          target="_blank"
                          rel="noopener"
                          title="title"
                        >
                          {title}
                        </a>
                      </h5>
                      <div className="my-1">
                        <time>{timeline}</time>
                      </div>
                    </header>
                    <p className="my-2">{description}</p>
                    <footer className="my-3">
                      <div className="preference-label mb-1">
                        <span>{builtWith.title}</span>
                      </div>
                      {builtWith.items.map(item => <span key={item} className="tag">{item}</span>)}
                    </footer>
                  </article>
                </div>))}
            </div>
          </section>
          <section className="resume-section my-4">
            <h4>{data.content.details.qualifications.title}</h4>
            <div className="row my-3" id="education">
              {data.content.details.qualifications.items.map(({ title, institution, timeline, credential }) => (<div className="col-12">
                <article>
                  <header className="my-2">
                    <h5>{title}</h5>
                    <span>{institution}</span>
                    <div className="my-1">
                      <time>{timeline}</time>
                    </div>
                    <span>
                      <a
                        href={credential.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {credential.title}
                      </a>
                    </span>
                  </header>
                  <p />
                </article>
              </div>
              ))}
            </div>
          </section>

          <section className="resume-section my-4">
            <h4>{data.content.details.education.title}</h4>
            <div className="row my-3" id="education">
              {data.content.details.education.items.map(({ degree, timeline, institution: { title, location, link } }) => (<div className="col-12">
                <article>
                  <header className="my-2">
                    <h5>{degree}</h5>
                    <div className="my-1">
                      <span>
                        <a
                          href={link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {title}
                        </a>
                      </span>{" "}
                      &nbsp;
                      <address>{location}</address>
                      <time>{timeline}</time>
                    </div>
                  </header>
                  <p />
                </article>
              </div>
              ))}
            </div>
          </section>
        </div>
      </div>
      <div className="my-5 text-center copyright">
        {data.content.footer.title}
      </div>
    </div>
  ) : (
      <div>Loading...</div>
    );
}

export default App;
